import { Box, Divider, styled } from '@mui/material';

import { BodyContainerInner, BodyContainerOuter } from '../BodyContainer';
import { HomeLink } from '../HomeLink';
import { GenerateMenu } from './components/menus/GenerateMenu';
import { ProfileMenu } from './components/menus/ProfileMenu';
import { NavProgress } from './components/NavProgress';
import { NavBarProps } from './types';

const NavHeader = styled(BodyContainerOuter)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
}));

const LogoRow = styled(BodyContainerInner)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
}));

export function NavBar({
  setNavPortalNode,
  hideNav,
  isAuthenticated,
}: NavBarProps) {
  return hideNav ? null : (
    <>
      <NavProgress />
      <NavHeader>
        <LogoRow paddingY={2}>
          <HomeLink isAuthenticated={isAuthenticated} />
          <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="flex-end"
            ref={setNavPortalNode}
          />
          <Box display="flex" gap={1} marginLeft="auto">
            <GenerateMenu />
            <Divider flexItem orientation="vertical" />
            <ProfileMenu />
          </Box>
        </LogoRow>
      </NavHeader>
    </>
  );
}
