import { createContext } from 'react';

type NavContext = {
  setDocTitle: (title: string) => void;
  navPortalNode: HTMLDivElement | undefined;
};

export const NavContext = createContext<NavContext>({
  setDocTitle: () => null,
  navPortalNode: undefined,
});
