import { styled } from '@mui/material';
import NextLink from 'next/link';

interface LinkProps {
  href: string;
  isInternal?: boolean;
  children: React.ReactNode;
  className?: string;
  underline?: boolean;
  onClick?: () => void;
  useCustomHover?: boolean;
}

interface StyledProps extends Pick<LinkProps, 'underline' | 'useCustomHover'> {}

const StyledLink = styled(NextLink, {
  shouldForwardProp: (prop) => {
    const dontForwardProps = ['underline', 'useCustomHover'];
    return !dontForwardProps.includes(prop as string);
  },
})<StyledProps>(({ theme, underline, useCustomHover }) => ({
  width: 'fit-content',
  color: theme.palette.text.primary,
  display: 'inline-block',
  textDecoration: underline ? 'underline' : 'none',
  ...(!useCustomHover && {
    ':hover': {
      color: theme.palette.text.secondary,
    },
  }),
}));

export function Link({
  href,
  isInternal = true,
  children,
  className,
  underline = true,
  onClick,
  useCustomHover,
}: LinkProps) {
  return (
    <StyledLink
      className={className}
      href={href}
      rel="noopener noreferrer"
      target={isInternal ? '_self' : '_blank'}
      underline={underline}
      useCustomHover={useCustomHover}
      onClick={onClick}
    >
      {children}
    </StyledLink>
  );
}
