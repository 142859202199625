import { styled } from '@mui/material';

import { HOME_PATH } from '~/constants/routes';
import { useBreakpointUp } from '~/libs/theme/hooks';

import { BwqMark } from './BwqMark';
import { Link } from './Link';

const HLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  :hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

interface HomeLinkProps {
  isAuthenticated: boolean;
}

export function HomeLink({ isAuthenticated }: HomeLinkProps) {
  const smUp = useBreakpointUp('sm');
  return (
    <HLink href={HOME_PATH} underline={false}>
      <BwqMark showMini={isAuthenticated || !smUp} />
    </HLink>
  );
}
