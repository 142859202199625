import { IconButton, Menu, MenuList, styled } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

import { Link } from '~/components/Link';

export const MenuLink = styled(Link)`
  display: flex;
  text-decoration: none;
`;

interface MenuIconButtonProps {
  open: boolean;
  menuId: string;
  buttonId: string;
  handleClick: ComponentProps<typeof IconButton>['onClick'];
  label: string;
}

export function MenuIconButton({
  open,
  menuId,
  buttonId,
  handleClick,
  label,
  children,
}: PropsWithChildren<MenuIconButtonProps>) {
  return (
    <IconButton
      aria-controls={open ? menuId : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      aria-label={label}
      id={buttonId}
      onClick={handleClick}
    >
      {children}
    </IconButton>
  );
}

interface NavMenuProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: ComponentProps<typeof Menu>['onClose'];
  buttonId: string;
  menuId: string;
}

export function NavMenu({
  open,
  anchorEl,
  onClose,
  children,
  buttonId,
  menuId,
}: PropsWithChildren<NavMenuProps>) {
  return (
    <Menu
      MenuListProps={{
        'aria-labelledby': buttonId,
      }}
      anchorEl={anchorEl}
      id={menuId}
      open={open}
      onClose={onClose}
    >
      <MenuList>{children}</MenuList>
    </Menu>
  );
}
