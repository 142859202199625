export type Route = `/${string}`;

export const CAMPAIGNS = 'campaigns';
export const NODE_TYPES = 'node-types';
export const NODES = 'nodes';
export const HOUSES = 'houses';
export const GENERATE = 'generate';
export const MAPS = 'maps';
export const PEOPLE = 'people';
export const PLACES = 'places';
export const EVENTS = 'events';
export const RELATIONSHIPS = 'relationships';
export const EVENT_TEMPLATES = 'event-templates';
export const USER = 'user';
export const USERS = 'users';
export const CREATE = 'create';
export const STATIC_MAPS = 'static-maps';

export const QUERY_HOUSE = 'house';
export const QUERY_CAMPAIGN = 'campaign';
export const QUERY_USERS = 'users';

export const TAB_QUERY = 'tab';
