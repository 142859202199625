import { Box, useTheme } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

interface BodyContainerBaseProps {
  outerSx?: ComponentProps<typeof Box>['sx'];
  innerSx?: ComponentProps<typeof Box>['sx'];
}

type BodyContainerProps = PropsWithChildren<BodyContainerBaseProps>;

export function BodyContainerOuter({
  children,
  ...rest
}: ComponentProps<typeof Box>) {
  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      position="relative"
      {...rest}
    >
      {children}
    </Box>
  );
}

export function BodyContainerInner({
  children,
  ...rest
}: ComponentProps<typeof Box>) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      maxWidth={theme.breakpoints.values.lg}
      paddingX={2}
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
}

export function BodyContainer({
  outerSx,
  innerSx,
  children,
}: BodyContainerProps) {
  return (
    <BodyContainerOuter sx={outerSx}>
      <BodyContainerInner sx={innerSx}>{children}</BodyContainerInner>
    </BodyContainerOuter>
  );
}
