import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useBreakpointDown = (breakPoint: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakPoint));
};

export const useBreakpointUp = (breakPoint: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakPoint));
};

export const useBreakpointExactly = (breakPoint: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.only(breakPoint));
};

export const useBreakpointCustom = (
  pxValue: number,
  direction: 'up' | 'down'
) => {
  const breakpoint = `${
    direction === 'up' ? 'min-width' : 'max-width'
  }: ${pxValue}px`;
  const matches = useMediaQuery(`(${breakpoint})`);

  return matches;
};
