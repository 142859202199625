import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import MuiMenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { signIn, signOut, useSession } from 'next-auth/react';

import { Avatar } from '~/components/Users/Avatar';
import { USERS_SETTINGS_ROUTE } from '~/constants/routes';
import { useBreakpointUp } from '~/libs/theme/hooks';

import { MenuIconButton, MenuLink, NavMenu } from './components';
import { useMenu } from './useMenu';

const buttonId = 'nav-menu-button';
const menuId = 'nav-menu';

// Adapted from https://mui.com/material-ui/react-menu/#main-content=
export function ProfileMenu() {
  const { open, anchorEl, handleClick, handleClose } = useMenu();
  const { data } = useSession();
  const user = data?.user;
  const theme = useTheme();

  const smUp = useBreakpointUp('sm');

  if (!user) {
    return (
      <Button
        sx={{
          marginLeft: 'auto',
        }}
        variant="contained"
        onClick={() => signIn()}
      >
        Log In
      </Button>
    );
  }

  return (
    <>
      {smUp ? (
        <Button
          aria-controls={open ? menuId : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          aria-label="Menu"
          id={buttonId}
          sx={{
            color: 'unset',
            textTransform: 'none',
          }}
          onClick={handleClick}
        >
          <Box display="flex" gap={1}>
            <Typography color={theme.palette.primary.main} fontWeight={600}>
              {user.name}
            </Typography>
            <Avatar
              alt={user.name || 'Profile'}
              height={24}
              src={user.image}
              width={24}
            />
          </Box>
        </Button>
      ) : (
        <MenuIconButton
          buttonId={buttonId}
          handleClick={handleClick}
          label="Profile Menu"
          menuId={menuId}
          open={open}
        >
          <MuiMenuIcon />
        </MenuIconButton>
      )}
      <NavMenu
        anchorEl={anchorEl}
        buttonId={buttonId}
        menuId={menuId}
        open={open}
        onClose={handleClose}
      >
        {user && (
          <>
            {!smUp && (
              <>
                <Typography p={2} pt={0}>
                  {user.name}
                </Typography>
                <Divider />
              </>
            )}
            <MenuItem>
              <MenuLink href={USERS_SETTINGS_ROUTE} underline={false}>
                <ListItemIcon>
                  <ManageAccounts />
                </ListItemIcon>
                <ListItemText>Profile Settings</ListItemText>
              </MenuLink>
            </MenuItem>
          </>
        )}

        <MenuItem onClick={() => (user ? signOut() : signIn())}>
          <ListItemIcon>{user ? <Logout /> : <Login />}</ListItemIcon>
          <ListItemText>{user ? 'Log Out' : 'Log In'}</ListItemText>
        </MenuItem>
      </NavMenu>
    </>
  );
}
