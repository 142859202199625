import { Box } from '@mui/material';
import { useSession } from 'next-auth/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { NavContext } from '~/libs/context/NavContext';

import { BodyContainer } from '../BodyContainer';
import { NavBanner, NavBannerProps } from '../NavBanner';
import { NavBar } from '../NavBar';
import { Breadcrumb, NavBreadcrumbs } from '../NavBreadcrumbs';
import { Head } from './Head';

type LayoutProps = {
  children?: React.ReactNode;
  title: string;
  meta: string;
  navBanner?: NavBannerProps;
  breadcrumbs?: Breadcrumb[];
  hideNav?: boolean;
};

export function Layout({
  children,
  title,
  meta,
  navBanner,
  breadcrumbs,
  hideNav,
}: LayoutProps) {
  const [docTitle, setDocTitle] = useState(title);
  const [navPortalNode, setNavPortalNode] = useState<HTMLDivElement>();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const setNavPortalNodeCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setNavPortalNode(node);
    }
  }, []);

  useEffect(() => {
    setDocTitle(title);
  }, [title]);

  const providerValue = useMemo(
    () => ({
      setDocTitle,
      navPortalNode,
    }),
    [setDocTitle, navPortalNode]
  );

  return (
    <NavContext.Provider value={providerValue}>
      <Head meta={meta} title={docTitle} />
      <NavBar
        hideNav={Boolean(hideNav)}
        isAuthenticated={isAuthenticated}
        setNavPortalNode={setNavPortalNodeCallback}
      />
      <Box display="flex" flexDirection="column" gap={1} mt={1}>
        <NavBreadcrumbs breadcrumbs={breadcrumbs} />
        <NavBanner {...navBanner} />
      </Box>
      <BodyContainer>{children}</BodyContainer>
    </NavContext.Provider>
  );
}
