import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { ElementType } from 'react';

import { beholdenFont } from '~/constants/theme';

import { Logo } from './Logo';

interface LogoAndTitleProps extends Pick<TypographyProps, 'variant'> {
  showMini?: boolean;
  logoless?: boolean;
  className?: string;
  component?: ElementType;
}

const QuestText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export function BwqMark({
  showMini,
  logoless,
  className,
  variant = 'h5',
  component = 'h1',
}: LogoAndTitleProps) {
  return (
    <Box
      alignItems="center"
      className={className}
      component="span"
      display="inline-flex"
    >
      {!logoless && <Logo />}
      <Typography
        className={beholdenFont.className}
        component={component}
        fontFamily={beholdenFont.style.fontFamily}
        fontWeight={500}
        lineHeight={0.1}
        variant={variant}
      >
        {!showMini ? (
          <span className={beholdenFont.className}>
            BoneWorld{' '}
            <QuestText
              as="span"
              className={beholdenFont.className}
              fontFamily={beholdenFont.style.fontFamily}
              variant="h6"
            >
              Quest
            </QuestText>
          </span>
        ) : (
          <span className={beholdenFont.className}>
            BW
            <QuestText
              as="span"
              className={beholdenFont.className}
              variant="h6"
            >
              Q
            </QuestText>
          </span>
        )}
      </Typography>
    </Box>
  );
}
