import { EmojiPeople, LocationCity } from '@mui/icons-material';
import React from 'react';

import { GENERATE_PEOPLE_PATH, GENERATE_PLACE_ROUTE } from '~/constants/routes';

export const NAV_LINKS: {
  label: string;
  href: string;
  MenuIcon: React.ComponentType;
}[] = [
  {
    label: 'Generate Person',
    href: GENERATE_PEOPLE_PATH,
    MenuIcon: EmojiPeople,
  },
  {
    label: 'Generate Place',
    href: GENERATE_PLACE_ROUTE,
    MenuIcon: LocationCity,
  },
];
