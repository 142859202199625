import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { NAV_LINKS } from '../../constants';
import { MenuIconButton, MenuLink, NavMenu } from './components';
import { useMenu } from './useMenu';

const buttonId = 'generate-menu-button';
const menuId = 'generate-menu';

export function GenerateMenu() {
  const { open, anchorEl, handleClose, handleClick } = useMenu();

  return (
    <>
      <MenuIconButton
        buttonId={buttonId}
        handleClick={handleClick}
        label="Generate"
        menuId={menuId}
        open={open}
      >
        <AutoAwesome />
      </MenuIconButton>
      <NavMenu
        anchorEl={anchorEl}
        buttonId={buttonId}
        menuId={menuId}
        open={open}
        onClose={handleClose}
      >
        {NAV_LINKS.map(({ label, href, MenuIcon }) => (
          <MenuItem key={href}>
            <MenuLink href={href}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuLink>
          </MenuItem>
        ))}
      </NavMenu>
    </>
  );
}
