import { Avatar as MuiAvatar } from '@mui/material';

interface AvatarProps {
  src: string | null | undefined;
  alt: string;
  letters?: string;
  className?: string;
  height?: number;
  width?: number;
}

export function Avatar({
  src,
  alt,
  className,
  letters,
  height = 40,
  width = 40,
}: AvatarProps) {
  return (
    <MuiAvatar
      alt={alt}
      className={className}
      src={src || undefined}
      sx={{ height, width }}
      title={alt}
      variant="rounded"
    >
      {letters?.slice(0, 2)}
    </MuiAvatar>
  );
}
